// FillingContent.js
import React from "react";
import buildadreamLogo from "../../resources/buildadream.svg";
import ScaleQuestion from "../smart-components/ScaleQuestion";
import "./fillingContent.css";

class FillingContent extends React.Component {
  render() {
    const { contents, handleQuestionUpdate, movePrevious, moveNext } =
      this.props;

    return (
      <div>
        <div className="header">
          <img src={buildadreamLogo} alt="stomach"></img>
          <p>Filling</p>
        </div>

        <div className="coverIntroText">
          <p className="textFirstRow">
            Rate how important each of the following criteria is regarding the
            material of the filling of your pillow!
          </p>
          <p className="textSecondRow">
            1 - not important at all, 4 - very important.
          </p>
        </div>

        {(contents.posture === "back" || contents.posture === "side") &&
          contents.physicalPain === null && (
            <ScaleQuestion
              text={
                "I want the filling of my pillow to handle frequent washing"
              }
              selectedButton={contents.filling.question1}
              category={"filling"}
              questionName={"question1"}
              toggle={false}
              handleButtonPress={handleQuestionUpdate}
            />
          )}

        {(contents.posture === "back" || contents.posture === "side") &&
          contents.physicalPain === null && (
            <ScaleQuestion
              text={
                "I want the filling of my pillow to be made of recycled material"
              }
              selectedButton={contents.filling.question2}
              category={"filling"}
              questionName={"question2"}
              toggle={false}
              handleButtonPress={handleQuestionUpdate}
            />
          )}

        {(contents.posture === "back" || contents.posture === "side") && (
          <ScaleQuestion
            text={
              "I want my pillow to provide the highest possible level of anatomical support"
            }
            selectedButton={contents.filling.question3}
            category={"filling"}
            questionName={"question3"}
            toggle={false}
            handleButtonPress={handleQuestionUpdate}
          />
        )}

        {(contents.posture === "back" || contents.posture === "side") && (
          <ScaleQuestion
            text={"I want my pillow to be as firm as possible"}
            selectedButton={contents.filling.question4}
            category={"filling"}
            questionName={"question4"}
            toggle={false}
            handleButtonPress={handleQuestionUpdate}
          />
        )}

        {contents.posture === "stomach" && (
          <ScaleQuestion
            text={"I want my pillow to be as flat as possible"}
            selectedButton={contents.filling.question5}
            category={"filling"}
            questionName={"question5"}
            toggle={false}
            handleButtonPress={handleQuestionUpdate}
          />
        )}

        {contents.posture === "stomach" && (
          <ScaleQuestion
            text={"I want my pillow to be as soft as possible"}
            selectedButton={contents.filling.question6}
            category={"filling"}
            questionName={"question6"}
            toggle={false}
            handleButtonPress={handleQuestionUpdate}
          />
        )}

        {contents.posture === "stomach" && contents.physicalPain === null && (
          <ScaleQuestion
            text={"I want my pillow to be more structured"}
            selectedButton={contents.filling.question7}
            category={"filling"}
            questionName={"question7"}
            toggle={false}
            handleButtonPress={handleQuestionUpdate}
          />
        )}

        {contents.posture === "stomach" && (
          <ScaleQuestion
            text={"I want the filling to be washable"}
            selectedButton={contents.filling.question9}
            category={"filling"}
            questionName={"question9"}
            toggle={false}
            handleButtonPress={handleQuestionUpdate}
          />
        )}

        <button className="backPostureButton" onClick={movePrevious}>
          <div className="backButtonArrow"></div>
        </button>
        <button className="nextPostureButton" onClick={moveNext}>
          Next
          <div className="startButtonArrow"></div>
        </button>
      </div>
    );
  }
}

export default FillingContent;
