import React from "react";
import allergicLogo from "../../resources/allerges/allergic.svg";
import allergicLogoWhite from "../../resources/allerges/allergic_white.svg";
import medicalLogo from "../../resources/allerges/medical.svg";
import medicalLogoWhite from "../../resources/allerges/medical_white.svg";
import microbesLogo from "../../resources/allerges/microbes.svg";
import microbesLogoWhite from "../../resources/allerges/microbes_white.svg";
import noneLogo from "../../resources/allerges/none.svg";
import noneLogoWhite from "../../resources/allerges/none_white.svg";
import sensitiveLogo from "../../resources/allerges/sensitive.svg";
import sensitiveLogoWhite from "../../resources/allerges/sensitive_white.svg";
import sweatingLogo from "../../resources/allerges/sweating.svg";
import sweatingLogoWhite from "../../resources/allerges/sweating_white.svg";
import buildadreamLogo from "../../resources/buildadream.svg";
import "./allergiesContent.css";

class AllergiesContent extends React.Component {
  render() {
    const { allergies, updateAllergies, movePrevious, moveNext  } = this.props;

    return (
      <div>
        <div className="header">
          <img src={buildadreamLogo} alt="buildadreamLogo"></img>
          <p>Do you have allergies, sensitivities, or special hygiene needs?</p>
        </div>

        <div className="buttonGridAllergies">
          <div className="allergiesFirstRow">
            <button
              onClick={() => updateAllergies("skin")}
              className={
                allergies.skin ? "selectedAllergiesButton" : "allergiesButton"
              }
            >
              <img
                src={allergies.skin ? sensitiveLogoWhite : sensitiveLogo}
                alt="skin"
              ></img>
              Very sensitive skin
            </button>
            <button
              onClick={() => updateAllergies("dust")}
              className={
                allergies.dust ? "selectedAllergiesButton" : "allergiesButton"
              }
            >
              <img
                src={allergies.dust ? allergicLogoWhite : allergicLogo}
                alt="dust"
              ></img>
              Allergic to dust
            </button>
            <button
              onClick={() => updateAllergies("microbe")}
              className={
                allergies.microbe
                  ? "selectedAllergiesButton"
                  : "allergiesButton"
              }
            >
              <img
                src={allergies.microbe ? microbesLogoWhite : microbesLogo}
                alt="microbe"
              ></img>
              Allergies triggered by microbes
            </button>
          </div>

          <div className="allergiesSecondRow">
            <button
              onClick={() => updateAllergies("medical")}
              className={
                allergies.medical
                  ? "selectedAllergiesButton"
                  : "allergiesButton"
              }
            >
              <img
                src={allergies.medical ? medicalLogoWhite : medicalLogo}
                alt="medical"
              ></img>
              Medical condition requiring medical - grade hygiene
            </button>
            <button
              onClick={() => updateAllergies("sweat")}
              className={
                allergies.sweat ? "selectedAllergiesButton" : "allergiesButton"
              }
            >
              <img
                src={allergies.sweat ? sweatingLogoWhite : sweatingLogo}
                alt="sweat"
              ></img>
              Prone to severe sweating
            </button>
            <button
              onClick={() => updateAllergies("none")}
              className={
                allergies.none ? "selectedAllergiesButton" : "allergiesButton"
              }
            >
              <img
                src={allergies.none ? noneLogoWhite : noneLogo}
                alt="none"
              ></img>
              No allergies or medical conditions
            </button>
          </div>
        </div>

        <button className="backPostureButton" onClick={movePrevious}>
          <div className="backButtonArrow"></div>
        </button>
        <button className="nextPostureButton" onClick={moveNext}>
          Next
          <div className="startButtonArrow"></div>
        </button>
      </div>
    );
  }
}

export default AllergiesContent;
