// App.js
import "@fontsource/poppins";
import "@fontsource/poppins/400-italic.css";
import "@fontsource/poppins/400.css";
import React from "react";
import Contents from "./components/contents/Contents";
import Sidebar from "./components/contents/Sidebar";
import StartContent from "./components/contents/StartContent";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showContent: false,
      selectedElement: "posture",
    };
    this.contentRef = React.createRef();
  }



  handleStartButton = () => {
    this.setState({ showContent: true });
  };

  openStart = () => {
    this.setState({
      showContent: false,
      selectedElement: "posture",
    });
  };

  openSummary = () => {
    this.setState({
      selectedElement: "summary",
    });
  };

  openStartAndClear = () => {
    this.setState({
      showContent: false,
      selectedElement: "posture",
    });
  };

  pageOrder = [
    "posture",
    "physicalPains",
    "allergies",
    "cover",
    "filling",
    "summary",
  ];

  movePrevious = () => {
    let previousPosition = this.pageOrder.indexOf(this.state.selectedElement);
    console.log("movePrevious");
    console.log(previousPosition);

    if (previousPosition === 0) {
      this.openStart();
    } else {
      this.setState({
        selectedElement: this.pageOrder[previousPosition - 1],
      });
    }
  };

  moveNext = () => {
    let previousPosition = this.pageOrder.indexOf(this.state.selectedElement);
    console.log("moveNext");
    console.log(previousPosition)

    if (previousPosition < 5) {
      this.setState({
        selectedElement: this.pageOrder[previousPosition + 1],
      });
    }
  };

  handleElementClick = (element) => {
    this.setState({
      selectedElement: element,
    });

    if (
      this.contentRef.current &&
      this.contentRef.current.resetSelectedButtons
    ) {
      this.contentRef.current.resetSelectedButtons();
    }
  };

  render() {
    return (
      <div>
        {this.state.showContent ? (
          <div style={{ display: "flex" }}>
            <Sidebar
              selectedElement={this.state.selectedElement}
              onElementClick={this.handleElementClick}
            />
            {/* Attach the ref to the Content component */}
            <Contents
              selectedElement={this.state.selectedElement}
              ref={this.contentRef}
              openStart={this.openStart}
              openSummary={this.openSummary}
              movePrevious={this.movePrevious}
              moveNext={this.moveNext}
            />
          </div>
        ) : (
          <StartContent handleStartButton={this.handleStartButton} />
        )}
      </div>
    );
  }
}

export default App;
