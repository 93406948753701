// Content.js
import React from "react";
import AllergiesContent from "./AllergiesContent";
import CoverContent from "./CoverContent";
import FillingContent from "./FillingContent";
import PhysicalPainContent from "./PhysicalPainContent";
import PostureContent from "./PostureContent";
import SummaryContent from "./SummaryContent";
import "./content.css";

class Contents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contents: {
        posture: "stomach",
        physicalPain: null,
        allergies: {
          skin: false,
          dust: false,
          microbe: false,
          medical: false,
          sweat: false,
          none: true,
        },
        zip: false,
        cover: {
          question1: null,
        },
        filling: {
          question1: null,
        },
      },
    };
  }

  onFinish = () => {
    this.setState({
      contents: {
        posture: "stomach",
        physicalPain: null,
        allergies: {
          skin: false,
          dust: false,
          microbe: false,
          medical: false,
          sweat: false,
          none: true,
        },
        zip: false,
        cover: {
          question1: null,
        },
        filling: {
          question1: null,
        },
      },
    });
    this.props.openStart();
  };

  showContent(element) {
    const contentElements = document.querySelectorAll(".hidden");
    contentElements.forEach((el) => {
      el.style.display = "none";
    });

    const selectedEl = document.getElementById(element);
    if (selectedEl) {
      selectedEl.style.display = "block";
    }
  }

  componentDidUpdate() {
    this.showContent(this.props.selectedElement);
  }

  handleQuestionUpdate = (category, questionNumber, rate) => {
    this.setState((prevState) => ({
      contents: {
        ...prevState.contents,
        [category]: {
          ...prevState.contents[category],
          [questionNumber]: rate,
        },
      },
    }));
  };

  updateAllergies = (allergy) => {
    if (allergy === "none") {
      this.setState((prevState) => ({
        contents: {
          ...prevState.contents,
          allergies: {
            skin: false,
            dust: false,
            microbe: false,
            medical: false,
            sweat: false,
            none: true,
          },
        },
      }));
    } else {
      this.setState((prevState) => ({
        contents: {
          ...prevState.contents,
          allergies: {
            ...prevState.contents.allergies,
            [allergy]: !prevState.contents.allergies[allergy],
            none: false,
          },
        },
      }));
    }
  };

  updatePosture = (postureValue) => {
    this.setState((prevState) => ({
      contents: {
        ...prevState.contents,
        posture: postureValue,
      },
    }));
  };

  updatePhysicalPains = (PhysicalPainsValue) => {
    this.setState((prevState) => ({
      contents: {
        ...prevState.contents,
        physicalPain: PhysicalPainsValue,
      },
    }));
    console.log(PhysicalPainsValue);
  };

  updateZip = () => {
    this.setState((prevState) => ({
      contents: {
        ...prevState.contents,
        zip: !prevState.contents.zip,
      },
    }));
  };

  render() {
    const { selectedElement, openSummary, openStart, movePrevious, moveNext } =
      this.props;
    const { contents } = this.state;

    return (
      <div id="content">
        {selectedElement === "posture" && (
          <PostureContent
            posture={contents.posture}
            updatePosture={this.updatePosture}
            openStart={openStart}
            movePrevious={movePrevious}
            moveNext={moveNext}
          />
        )}
        {selectedElement === "physicalPains" && (
          <PhysicalPainContent
            physicalPain={contents.physicalPain}
            updatePhysicalPains={this.updatePhysicalPains}
            movePrevious={movePrevious}
            moveNext={moveNext}
          />
        )}
        {selectedElement === "allergies" && (
          <AllergiesContent
            allergies={contents.allergies}
            updateAllergies={this.updateAllergies}
            movePrevious={movePrevious}
            moveNext={moveNext}
          />
        )}
        {selectedElement === "cover" && (
          <CoverContent
            contents={contents}
            handleQuestionUpdate={this.handleQuestionUpdate}
            updateZip={this.updateZip}
            movePrevious={movePrevious}
            moveNext={moveNext}
          />
        )}
        {selectedElement === "filling" && (
          <FillingContent
            contents={this.state.contents}
            handleQuestionUpdate={this.handleQuestionUpdate}
            openSummary={openSummary}
            movePrevious={movePrevious}
            moveNext={moveNext}
          />
        )}
        {selectedElement === "summary" && (
          <SummaryContent
            contents={this.state.contents}
            movePrevious={movePrevious}
            onFinish={this.onFinish}
            posture={contents.posture}
            allergies={contents.allergies}
          />
        )}
      </div>
    );
  }
}

export default Contents;
