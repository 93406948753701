import React, { Component } from "react";
import "./scaleQuestion.css";

class ScaleQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
    };
  }

  render() {
    const {
      text,
      selectedButton,
      handleButtonPress,
      category,
      questionName,
      toggle,
      toggleState,
      setToggle,
      toggleText,
      altToggleText,
    } = this.props;

    return (
      <div className="background">
        <p className="question">{text}</p>
        <div className="scale">
          {Array.from({ length: 4 }, (_, index) => (
            <button
              onClick={() =>
                handleButtonPress(category, questionName, index + 1)
              }
              className={selectedButton === index + 1 ? "selected" : "default"}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    );
  }
}

export default ScaleQuestion;
