// PostureContent.js
import React from "react";
import "./PostureContent.css";
import stomachLogo from "../../resources/position/stomach.svg";
import stomachLogoWhite from "../../resources/position/stomach_white.svg";
import backLogo from "../../resources/position/back.svg";
import backLogoWhite from "../../resources/position/back_white.svg";
import sideLogo from "../../resources/position/side.svg";
import sideLogoWhite from "../../resources/position/side_white.svg";
import buildadreamLogo from "../../resources/buildadream.svg";

class PostureContent extends React.Component {
  render() {
    const { posture, updatePosture, movePrevious, moveNext } = this.props;

    return (
      <div>
        <div className="header">
          <img src={buildadreamLogo} alt="stomach"></img>
          <p>In which position do you usually sleep for the longest time?</p>
        </div>

        <div className="buttonGrid">
          <div className="firstRow">
            <button
              onClick={() => updatePosture("stomach")}
              className={
                posture === "stomach"
                  ? "selectedPostureButton"
                  : "postureButton"
              }
            >
              <img
                src={posture === "stomach" ? stomachLogoWhite : stomachLogo}
                alt="stomach"
              ></img>
              On my stomach
            </button>
            <button
              onClick={() => updatePosture("back")}
              className={
                posture === "back" ? "selectedPostureButton" : "postureButton"
              }
            >
              <img
                src={posture === "back" ? backLogoWhite : backLogo}
                alt="back"
              ></img>
              On my back
            </button>
            <button
              onClick={() => updatePosture("side")}
              className={
                posture === "side" ? "selectedPostureButton" : "postureButton"
              }
            >
              <img
                src={posture === "side" ? sideLogoWhite : sideLogo}
                alt="side"
              ></img>
              On my side
            </button>
          </div>
        </div>

        <button className="backPostureButton" onClick={movePrevious}>
          <div className="backButtonArrow"></div>
        </button>
        <button className="nextPostureButton" onClick={moveNext}>
          Next
          <div className="startButtonArrow"></div>
        </button>
      </div>
    );
  }
}

export default PostureContent;
