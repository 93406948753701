// Sidebar.js
import React, { Component } from "react";
import "./sidebar.css";

class Sidebar extends Component {
  handleElementClick = (element) => {
    this.props.onElementClick(element);
  };

  render() {
    const { selectedElement } = this.props;

    return (
      <div id="sidebar">
        <div
          className="menuRow"
          onClick={() => this.handleElementClick("posture")}
        >
          <div
            className={
              selectedElement === "posture"
                ? "selectedCircle"
                : "unselectedCircle"
            }
          />
          <div className="posture">Posture</div>
        </div>
        <div
          className="menuRow"
          onClick={() => this.handleElementClick("physicalPains")}
        >
          <div
            className={
              selectedElement === "physicalPains"
                ? "selectedCircle"
                : "unselectedCircle"
            }
          />
          <div className="physicalPains">Physical pains</div>
        </div>
        <div
          className="menuRow"
          onClick={() => this.handleElementClick("allergies")}
        >
          <div
            className={
              selectedElement === "allergies"
                ? "selectedCircle"
                : "unselectedCircle"
            }
          />
          <div className="allergies">Allergies</div>
        </div>
        <div
          className="menuRow"
          onClick={() => this.handleElementClick("cover")}
        >
          <div
            className={
              selectedElement === "cover"
                ? "selectedCircle"
                : "unselectedCircle"
            }
          />
          <div className="cover">Cover</div>
        </div>
        <div
          className="menuRow"
          onClick={() => this.handleElementClick("filling")}
        >
          <div
            className={
              selectedElement === "filling"
                ? "selectedCircle"
                : "unselectedCircle"
            }
          />
          <div className="filling">Filling</div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
