import React from "react";
import "./physicalPainContent.css";
import neckPainLogo from "../../resources/physicalPain/neck_pain.svg";
import neckPainLogoWhite from "../../resources/physicalPain/neck_pain_white.svg";
import backPainLogo from "../../resources/physicalPain/back_pain.svg";
import backPainLogoWhite from "../../resources/physicalPain/back_pain_white.svg";
import shoulderPainLogo from "../../resources/physicalPain/shoulder_pain.svg";
import shoulderPainLogoWhite from "../../resources/physicalPain/shoulder_pain_white.svg";
import noneLogo from "../../resources/physicalPain/none.svg";
import noneLogoWhite from "../../resources/physicalPain/none_white.svg";
import buildadreamLogo from "../../resources/buildadream.svg";

class PhysicalPainContent extends React.Component {
  render() {
    const { physicalPain, updatePhysicalPains, movePrevious, moveNext } = this.props;

    return (
      <div>
        <div className="header">
          <img src={buildadreamLogo} alt="buildadreamLogo"></img>
          <p>
            Do you experience
            <br />
            chronic pain?
          </p>
        </div>

        <div className="buttonGridPhysical">
          <div className="physicalFirstRow">
            <button
              onClick={() => updatePhysicalPains("neck")}
              className={
                physicalPain === "neck"
                  ? "selectedPhysicalPainButton"
                  : "physicalPainButton"
              }
            >
              <img
                src={physicalPain === "neck" ? neckPainLogoWhite : neckPainLogo}
                alt="neck"
              />
              Neck pain
            </button>
            <button
              onClick={() => updatePhysicalPains("back")}
              className={
                physicalPain === "back"
                  ? "selectedPhysicalPainButton"
                  : "physicalPainButton"
              }
            >
              <img
                src={physicalPain === "back" ? backPainLogoWhite : backPainLogo}
                alt="back"
              />
              Back pain
            </button>
            <button
              onClick={() => updatePhysicalPains("shoulder")}
              className={
                physicalPain === "shoulder"
                  ? "selectedPhysicalPainButton"
                  : "physicalPainButton"
              }
            >
              <img
                src={
                  physicalPain === "shoulder"
                    ? shoulderPainLogoWhite
                    : shoulderPainLogo
                }
                alt="shoulder"
              />
              Shoulder pain
            </button>
            <button
              onClick={() => updatePhysicalPains(null)}
              className={
                physicalPain === null
                  ? "selectedPhysicalPainButton"
                  : "physicalPainButton"
              }
            >
              <img
                src={physicalPain === null ? noneLogoWhite : noneLogo}
                alt="none"
              />
              None
            </button>
          </div>
        </div>

        <button className="backPostureButton"onClick={movePrevious}>
          <div className="backButtonArrow"></div>
        </button>
        <button className="nextPostureButton"onClick={moveNext}>
          Next
          <div className="startButtonArrow"></div>
        </button>


      </div>
    );
  }
}

export default PhysicalPainContent;
